
import ShopCallout from '../layouts/shop/components/callouts/ShopCallout';
import BannerCarousel from "@/layouts/shop/components/banners/BannerCarousel";
import MainPageCalloutList from "@/layouts/shop/components/callouts/MainPageCalloutList";
import TextBannerList from "@/layouts/shop/components/banners/Text/TextBannerList.vue";

export default {
  name: 'Index',
  components: {TextBannerList, MainPageCalloutList, BannerCarousel, ShopCallout },
  async asyncData({ app, error }) {
    const req = await app.$productRepository.getProducts().catch((e) => {
      return error({ statusCode: e.response?.status});
    });

    return {
      products: req.data
    };
  },
};
